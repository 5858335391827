// ACTION TYPES

export const ADD_TO_QUEUE = "fileUpload/ADD_TO_QUEUE";
export const MOVE_TO_INPROGRESS = "fileUpload/MOVE_TO_INPROGRESS";
export const MOVE_TO_DONE = "fileUpload/MOVE_TO_DONE";
export const UPDATE_PROGRESS = "fileUpload/UPDATE_PROGRESS";
export const ALL_DONE = "fileUpload/ALL_DONE";

// ACTION CREATORS

export const addToQueue = (files) => ({
  type: ADD_TO_QUEUE,
  payload: {
    files,
  },
});

export const moveFromQueue = (number) => ({
  type: MOVE_TO_INPROGRESS,
  payload: {
    number,
  },
});

export const onUploadFinished = (fileId) => ({
  type: MOVE_TO_DONE,
  payload: {
    fileId,
  },
});

export const onUploadProgress = (fileId, info) => ({
  type: UPDATE_PROGRESS,
  payload: {
    fileId,
    info,
  },
});

export const allDone = () => ({
  type: ALL_DONE,
});
