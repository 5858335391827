import { getIsParallel, getRunScriptValue } from "../helpers";
import { CreateCaseContextType } from "../inititalState";

/**
 * Function that updates the runScript based on the number of CPUs
 * that the user selects on UI
 * This function updates the runScript when the user selects
 * more than 2 vcpus
 * @param payload
 * @param state
 * @returns
 */
export function setCpuAndRunScript(payload: { numCPUs: number }, state: CreateCaseContextType): CreateCaseContextType {
  const { numCPUs } = payload;
  const { solverName } = state;

  const sliderValue = numCPUs > 1 ? "1" : "0";
  const parallel = getIsParallel(sliderValue, numCPUs);

  // we dont update the runScript when the cpu selection change
  const canUpdateRunScript = !state.isSesesEnabled;

  let runScript = state.runScript;

  if (canUpdateRunScript) {
    // update runScript if user selects more than 2vCPUs
    runScript = getRunScriptValue(solverName, numCPUs, sliderValue, parallel, state.runScript);
  }

  return {
    ...state,
    numCPUs,
    parallel,
    sliderValue,
    runScript,
  };
}

export default setCpuAndRunScript;
