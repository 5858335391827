/**
 * Initial State for create case context reducer
 * And type for the store
 * Some fields need to have specific types
 */
export type CreateCaseContextType = {
  openFoam: string;
  projectId: string;
  caseId: string;
  files: any[];
  name: string;
  solverName: string;
  open: boolean;
  machine: any;
  machineDetail: object;
  cloudProvider: string;
  emailNotification: boolean;
  numCPUs: number;
  decomposeParDict: string;
  parallel: boolean;
  sliderValue: string;
  cpu: any;
  create: boolean;
  errors: object;
  runScript: string;
  backTo: string;
  displayCard: boolean;
  redirectToProjectPage: boolean;
  displayGpuCard: boolean;
  isSesesEnabled: boolean;
  json: {
    parameterItems: any[];
    data: object;
    defaultJson: object; // load this value from DB filtered by software version
  };
  gpuType: object;
  softwareLicenseId: string;
  softwareLicenseName: string;
};

export const initialState = {
  openFoam: "",
  projectId: "",
  caseId: "",
  files: [],
  name: "",
  solverName: "",
  open: false,
  machine: null,
  machineDetail: {},
  cloudProvider: "gcp",
  emailNotification: false,
  numCPUs: 0,
  decomposeParDict: "",
  parallel: false,
  // refs: T973
  // since we give 2 different parallel option now
  // need to store the index of slider
  sliderValue: "0", // 0 - single core, 1 - physical core, 2 - virtual core
  cpu: "",
  create: true,
  errors: {},
  runScript: "",
  backTo: "",
  displayCard: false,
  redirectToProjectPage: false,
  displayGpuCard: false,
  isSesesEnabled: false,
  json: {
    parameterItems: [],
    data: {},
    defaultJson: {}, // load this value from DB filtered by software version
    expandAllRows: false
  },
  gpuType: {},
  softwareLicenseId: "",
  softwareLicenseName: "",
  licenseServer: "",
};
