import React, { Suspense, lazy } from "react";
import domainStore from "../../../helpers/kcc/DomainStore";
import { getKccLogo } from "../../../helpers/kcc/kccLogo";
import SimvantageLogo from "./SimvantageLogo";

const DefaultSmallLogo = lazy(() => import("./DefaultSmallLogo"));

/**
 * Component that loads the logo given the node_env or domain url
 * @param {*} props
 * @returns
 */
function ThemedLogo(props) {
  let Logo = SimvantageLogo;

  // Override with a specific KCC logo if applicable
  const kccLogo = getKccLogo(domainStore.getDomain());
  if (kccLogo) {
    Logo = kccLogo;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Logo {...props} />
    </Suspense>
  );
}

export function ThemedSmallLogo(props) {
  const Logo = DefaultSmallLogo;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Logo {...props} />
    </Suspense>
  );
}

export default ThemedLogo;
