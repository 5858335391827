import { Action, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkAction } from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import client from "../apollo";
import rootReducer from "./rootReducer";

let store;
/**
 * Configure redux toolkit with
 * old redux configuration
 * @param initialState
 * @returns
 */
export default function configureReduxStore(initialState: any) {
  const middleware = [thunk.withExtraArgument({ client })];

  store = configureStore({
    reducer: rootReducer, // rootReducer is combined reducer
    preloadedState: initialState,
    middleware,
    devTools: process.env.NODE_ENV !== "production",
  });

  return { store };
}

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
