import { uniqBy } from "lodash";

import {
  SELECT_ALL,
  UNDO_SELECT_ALL,
  SELECT_CASE,
  UNDO_SELECT_CASE,
  INIT_CASE,
  INIT_ALL_CASES,
  RESET_STATE,
  REMOVE_CASE,
  SELECT_OPEN,
  SELECT_RUNNING,
  SELECT_COMPLETED,
  SELECT_FAILED,
  REMOVE_CASES,
} from "./actions";
import { getCaseStatus } from "../caseStatus/reducer";
import {
  frontendStates,
  COMPLETEDSTATE,
  FAILEDSTATE,
  STARTUPSTATE,
  RESERVEVMSTATE,
  STARTVMSTATE,
  WAITFORVMTOSTARTSTATE,
  VMRUNNINGSTATE,
} from "../../libs/sm-constants/src/smStates";

const caseStatusReducer = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_CASE:
      const newState = state.slice();
      // new checkbox to be added
      const newCheckBox = {
        caseId: payload.caseId,
        checked: false, // init cases with checked=false
      };
      newState.splice(newState.length, 1, newCheckBox);
      return uniqBy(newState, "caseId");
    case INIT_ALL_CASES:
      return uniqBy(
        payload.map((item) => ({ caseId: item._id, checked: false })),
        "caseId",
      );
    case SELECT_ALL: {
      return state.map((checkbox) => ({
        ...checkbox,
        checked: true,
      }));
    }

    case SELECT_OPEN: {
      const { store } = payload;
      return state.map((checkbox) => {
        const caseStatus = getCaseStatus(store, checkbox.caseId);
        const checked = caseStatus.state === frontendStates.OPENSTATE;
        return {
          ...checkbox,
          checked,
        };
      });
    }

    case SELECT_COMPLETED: {
      const { store } = payload;
      return state.map((checkbox) => {
        const caseStatus = getCaseStatus(store, checkbox.caseId);
        const checked = caseStatus.state === COMPLETEDSTATE;
        return {
          ...checkbox,
          checked,
        };
      });
    }

    case SELECT_RUNNING: {
      const { store } = payload;
      return state.map((checkbox) => {
        const caseStatus = getCaseStatus(store, checkbox.caseId);
        const checked =
          caseStatus.state === STARTUPSTATE ||
          caseStatus.state === RESERVEVMSTATE ||
          caseStatus.state === STARTVMSTATE ||
          caseStatus.state === WAITFORVMTOSTARTSTATE ||
          caseStatus.state === VMRUNNINGSTATE;
        return {
          ...checkbox,
          checked,
        };
      });
    }

    case SELECT_FAILED: {
      const { store } = payload;
      return state.map((checkbox) => {
        const caseStatus = getCaseStatus(store, checkbox.caseId);
        const checked = caseStatus.state === FAILEDSTATE;
        return {
          ...checkbox,
          checked,
        };
      });
    }

    case SELECT_CASE:
      return state.map((checkbox) => {
        if (checkbox.caseId !== payload.caseId) {
          return checkbox;
        }
        // Set value to true
        return {
          ...checkbox,
          checked: true,
        };
      });
    case UNDO_SELECT_ALL:
      return state.map((checkbox) =>
        // Set value to false
        ({
          ...checkbox,
          checked: false,
        }),
      );
    case UNDO_SELECT_CASE:
      return state.map((checkbox) => {
        if (checkbox.caseId !== payload.caseId) {
          return checkbox;
        }
        // Set value to false
        return {
          ...checkbox,
          checked: false,
        };
      });
    case REMOVE_CASE:
      const itemIndex = state.findIndex((checkbox) => checkbox.caseId === payload.caseId);
      if (itemIndex !== -1) {
        return [...state.slice(0, itemIndex), ...state.slice(itemIndex + 1)];
      }
      return state;
    case REMOVE_CASES:
      return state.filter((checkbox) => !payload.cases.includes(checkbox.caseId));
    case RESET_STATE:
      return [];
    default:
      return state;
  }
};

// selector
export const getCaseCheckBoxStatus = (state, caseId) =>
  state.caseListCheckBox.length > 0 ? state.caseListCheckBox.find((el) => el.caseId === caseId) : null;

export const getSelectAllStatus = (state) => {
  const isAllChecked = state.caseListCheckBox.every((item) => item.checked);
  const isSomeChecked = state.caseListCheckBox.some((item) => item.checked);
  if (isAllChecked && state.caseListCheckBox.length) return "all";
  if (isSomeChecked) return "some";
  return "none";
};

export const getSelectedCases = (state) =>
  state.caseListCheckBox.filter((item) => item.checked).map((item) => item.caseId);

export default caseStatusReducer;
