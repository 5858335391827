import domainStore from "./helpers/kcc/DomainStore";
import { getKccDomain, isKccUrl } from "./libs/kcc/domainUrls";

// Feature constants
export const CASE_RESIDUALS = "CASE_RESIDUALS";
export const AUTH_2FA = "AUTH_2FA";
export const TERMINATE_MACHINE = "TERMINATE_MACHINE";
export const STOP_SIMULATION_STEP = "STOP_SIMULATION_STEP";
export const AIBAT = "AIBAT";
export const BULK_DOWNLOAD = "BULK_DOWNLOAD";
export const TUTORIAL = "TUTORIAL";
export const TOGGLE = "TOGGLE";
// this is used for environments that have payment available (used on signup form)
export const SIGNUP_DISPLAY_CPU_HOURS_TEXT = "SIGNUP_DISPLAY_CPU_HOURS_TEXT";
export const ENABLE_SIGNUP = "ENABLE_SIGNUP";

export const featureFlagsConfig = {
  development: {
    [AUTH_2FA]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [AIBAT]: true,
    [TUTORIAL]: true,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
    [ENABLE_SIGNUP]: false,
  },
  staging: {
    [AUTH_2FA]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [AIBAT]: true,
    [TUTORIAL]: true,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
    [ENABLE_SIGNUP]: true,
  },
  production: {
    [AUTH_2FA]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [AIBAT]: true,
    [TUTORIAL]: true,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
    [ENABLE_SIGNUP]: true,
  },
  simvantage: {
    [AUTH_2FA]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [AIBAT]: true,
    [TUTORIAL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
    [ENABLE_SIGNUP]: true,
  },
};

/**
 * It load the feature flags for the
 * different envs
 * @param {string} env
 * @returns
 */
const getFeatureFlagsForEnv = (env) => {
  let domain = domainStore.getDomain();
  const currentUrl = `${window.location.protocol}//${window.location.host}`; // returns the absolute URL of a page

  const isKccDom = isKccUrl(currentUrl);

  if (isKccDom) {
    const domainData = getKccDomain(currentUrl);
    domain = { url: currentUrl, domain: domainData };
    domainStore.storeDomain(domain);
  }

  if (domain !== null) {
    const data = featureFlagsConfig[domain.domain];
    return data;
  }

  if (env in featureFlagsConfig) {
    return featureFlagsConfig[env];
  }

  throw new Error("No featureFlags config present for current env");
};

export default getFeatureFlagsForEnv;
