import {
  CLEAN_CASE_FILES,
  FETCH_ALL_CASEFILES_ERROR,
  FETCH_ALL_CASEFILES_SUCCESS,
  FETCH_ALL_CASEFILES_START,
  FETCH_FILE_EXTENSIONS_START,
  FETCH_FILE_EXTENSIONS_SUCCESS,
  FETCH_FILE_EXTENSIONS_ERROR,
  FETCH_COMMON_FILES_START,
  FETCH_COMMON_FILES_SUCCESS,
  FETCH_COMMON_FILES_ERROR,
} from "./actions";
import { convertPathToTreeCheckbox } from "../../components/projects/download-files/utils/buildTreeCheckboxNodes";

/* initial state */
const initialState = {
  commonFiles: { loading: true, data: [] },
  allCaseFiles: { loading: true, data: [] },
  fileExtensions: { loading: true, data: [] },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAN_CASE_FILES: {
      return initialState;
    }
    case FETCH_ALL_CASEFILES_START: {
      return {
        ...state,
        allCaseFiles: { loading: true, data: [] },
      };
    }
    case FETCH_ALL_CASEFILES_SUCCESS: {
      return { ...state, allCaseFiles: { loading: false, success: true, data: payload.data } };
    }
    case FETCH_ALL_CASEFILES_ERROR: {
      const { error } = payload;
      return {
        ...state,
        allCaseFiles: { loading: false, success: false, error },
      };
    }
    case FETCH_FILE_EXTENSIONS_START: {
      return {
        ...state,
        fileExtensions: { loading: true },
      };
    }
    case FETCH_FILE_EXTENSIONS_SUCCESS: {
      return { ...state, fileExtensions: { loading: false, success: true, data: payload.data } };
    }
    case FETCH_FILE_EXTENSIONS_ERROR: {
      const { error } = payload;
      return { ...state, fileExtensions: { loading: false, success: false, error } };
    }
    case FETCH_COMMON_FILES_START: {
      return {
        ...state,
        commonFiles: { loading: true },
      };
    }
    case FETCH_COMMON_FILES_SUCCESS: {
      return { ...state, commonFiles: { loading: false, success: true, data: payload.data } };
    }
    case FETCH_COMMON_FILES_ERROR: {
      const { error } = payload;
      return { ...state, commonFiles: { loading: false, success: false, error } };
    }
    default:
      return state;
  }
}

/**
 * Return only the common extensions
 * @param {Object} state
 */
export const getFilesExtensions = (state) => {
  const extensions = [];

  // validates loading state
  if (state.caseFiles.loading) {
    return { loading: true, extensions: [] };
  }

  // creates an array with all the extensions
  state.caseFiles.data.map((caseVal) => {
    if (!extensions.includes(caseVal.fileExt)) {
      if (caseVal.fileExt !== "") {
        extensions.push(caseVal.fileExt);
      } else if (!extensions.includes("")) {
        extensions.push("");
      }
    }
    return true;
  });
  return { loading: false, extensions };
};

/**
 * Filter selected cases and gives them
 * a valid format for the TreeCheckbox component
 * @param {Object} state
 * @param {Array} selectedCases
 */
export const filterCasesForTreeCheckbox = (caseFiles) => {
  // prepare cases for TreeCheckbox
  const groupByCaseId = (array, key) => {
    // group by caseId
    const dataGrouped = array.reduce((result, obj) => {
      const partialResult = result;
      (partialResult[obj[key]] = partialResult[obj[key]] || []).push(obj);
      return partialResult;
    }, {});

    const arrayData = [];
    // create an array with the grouped data [[caseId1],[caseId2],...]
    Object.keys(dataGrouped).forEach((objKey) => {
      arrayData.push(dataGrouped[objKey]);
    });
    return arrayData;
  };

  const casesGrouped = groupByCaseId(caseFiles, "caseId");

  const treeData = convertPathToTreeCheckbox(casesGrouped);
  return treeData;
};
