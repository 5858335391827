/* eslint-disable react/forbid-component-props */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import styled from "styled-components";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown } from "react-country-region-selector";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Row,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";

import PasswordField from "./PasswordField";
import LayoutLogo from "../../common/LayoutLogo";
import TextInputInline from "../../common/TextInputInline";
import { personalAccount, confirmEmail } from "../../../queries/user-queries";
import Captcha from "./Captcha";
import ConfirmEmail from "../confirm-email/ConfirmEmail";
import { validateForm } from "./validateSignupForm";
import ConfirmCreatedAccountModal from "./ConfirmCreatedAccountModal";
import { SIGNUP_DISPLAY_CPU_HOURS_TEXT } from "../../../featureFlags";
import FeatureToggle from "../../common/FeatureToggle";
import { getEnvironmentName } from "../../../config/config";

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeatPassword: "",
      error: [],
      redirectUserPage: false,
      captcha: false,
      captchaToken: "",
      countryCode: "",
      loading: false,
      termsAndConditions: false,
      redirectToLoginPage: false,
      country: "",
    };

    this.verifyCallback = this.verifyCallback.bind(this);
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckbox = event => {
    this.setState({ termsAndConditions: event.target.checked });
  };

  onSubmit = async event => {
    event.preventDefault();
    const { token } = this.props?.match?.params;
    const { formIsValid, error } = validateForm(this.state, token);
    if (formIsValid) {
      this.createPersonalAccount();
      this.setState({ error: [] });
    } else {
      toast.error("Complete the required fields!");
      this.setState({ error });
    }
  };

  selectCountry = val => {
    this.setState({ country: val });
  };

  async createPersonalAccount() {
    this.setState({ loading: true, captcha: false });
    const { firstName, lastName, email, password, country, captchaToken } = this.state;
    const { token } = this.props?.match?.params;

    const { data } = await this.props.createPersonalAccount({
      variables: {
        user: {
          firstName,
          lastName,
          email,
          password,
          country,
        },
        token,
        captchaToken,
      },
    });

    this.setState({ userId: data.createPersonalAccount.userId });

    if (data.createPersonalAccount.success) {
      this.setState({ userId: data.createPersonalAccount.userId });

      // if token does not exists we sent the confirmation email
      if (!token) {
        await this.props.confirmEmail({
          variables: {
            userId: data.createPersonalAccount.userId,
            email,
          },
        });

        this.setState({ redirectUserPage: true });
        toast.success("Please confirm your email address");
      } else {
        // if token does not exist, then just notify that the account has been created
        toast.success("Account created successfully.");
        this.setState({ redirectToLoginPage: true });
      }

      this.setState({ loading: false });
      // return;
    }

    this.setState({ loading: false });
    const formError = {};
    formError[data.createPersonalAccount.errors[0].path] = data.createPersonalAccount.errors[0].message;
    this.setState({ error: formError });

    return toast.error("Complete the required fields!");
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        captcha: true,
        captchaToken: response,
      });
    } else {
      this.setState({ captcha: false, captchaToken: "" });
    }
  }

  render() {
    const envName = getEnvironmentName();
    return (
      <>
        {this.state.redirectUserPage ? (
          <ConfirmEmail email={this.state.email} userId={this.state.userId} modal />
        ) : null}
        {this.state.redirectToLoginPage && <ConfirmCreatedAccountModal />}
        <div className="app align-items-center">
          <Container>
            <LayoutLogo />
            <Row className="justify-content-center">
              <Col md="8">
                <Card className="p-4">
                  <CardBody className="p-4">
                    <Form id={`app-register-${envName}`} onSubmit={(e) => this.onSubmit(e)}>
                      <h1>Registration</h1>
                      <FeatureToggle
                        flag={SIGNUP_DISPLAY_CPU_HOURS_TEXT}
                        activeComponent={
                          <h5>Create your account and receive 50 free cloud-hours with your first purchase.</h5>
                        }
                        inactiveComponent={
                          <h5>Register now to experience the best in advanced computational engineering.</h5>
                        }
                      />
                      <TextInputInline
                        testId="input-first-name"
                        groupClass="mb-3"
                        required
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        autoComplete="firstName"
                        onChange={this.onChange}
                        icon="icon-user"
                      />
                      <SpanWrapper>{this.state.error.fname}</SpanWrapper>
                      <TextInputInline
                        testId="input-last-name"
                        groupClass="mb-3"
                        required
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        autoComplete="lastName"
                        onChange={this.onChange}
                        icon="icon-user"
                      />
                      <SpanWrapper>{this.state.error.lname}</SpanWrapper>
                      <TextInputInline
                        testId="input-email"
                        groupClass="mb-3"
                        required
                        type="text"
                        name="email"
                        placeholder="Email"
                        autoComplete="email"
                        onChange={this.onChange}
                        icon="fa fa-at"
                      />
                      <SpanWrapper>{this.state.error.email}</SpanWrapper>
                      <DivBordered>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-globe" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <CountryDropdown
                            data-test-id="dropdown-country"
                            value={this.state.country}
                            onChange={val => this.selectCountry(val)}
                            priorityOptions={["CH"]}
                            style={{
                              margin: "10px",
                              border: "0px",
                              borderRadius: "0px",
                              outline: 0,
                              color: "gray",
                              backgroundColor: "#ffffff",
                              width: "90%",
                            }}
                          />
                        </InputGroup>
                      </DivBordered>
                      <SpanWrapper>{this.state.error.country}</SpanWrapper>
                      <PasswordField
                        label="Password"
                        name="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        onChange={this.onChange}
                        thresholdlength={7}
                        minstrength={3}
                      />
                      <SpanWrapper>{this.state.error.password}</SpanWrapper>
                      <TextInputInline
                        testId="input-password-repeat"
                        groupClass="mb-3"
                        type="password"
                        name="repeatPassword"
                        placeholder="Repeat password"
                        autoComplete="rep_pass"
                        onChange={this.onChange}
                        icon="icon-lock"
                      />
                      <SpanWrapper>{this.state.error.password2}</SpanWrapper>
                      <DivBordered>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <Input
                                data-test-id="checkbox-terms-conditions"
                                addon
                                type="checkbox"
                                onChange={this.handleCheckbox}
                                aria-label="click the terms and conditions"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <CenteredLabel>
                            I agree to the&nbsp;
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://kaleidosim.com/terms-conditions/"
                            >
                              Terms of Service and the Data Processing Agreement.
                            </a>
                          </CenteredLabel>
                        </InputGroup>
                      </DivBordered>
                      <SpanWrapper>{this.state.error.termsAndConditions}</SpanWrapper>
                      <Captcha verifyCallback={this.verifyCallback} isCaptchaValid={this.state.captcha} />
                      <SpanWrapper>{this.state.error.captcha}</SpanWrapper>
                      <Button
                        color="success"
                        type="submit"
                        disabled={this.state.loading}
                        block
                        data-test-id="button-create-account"
                      >
                        {this.state.loading ? <i className="fa fa-spinner fa-spin fa-1x fa-fw" /> : null}
                        &nbsp;Create Account
                      </Button>
                    </Form>
                  </CardBody>
                  <CardFooter className="p-4 d-flex align-items-center">
                    <h4>Do you already have an account?</h4>
                    <Link to="/login">
                      <Button className="ml-2" color="primary">
                        Login
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const DivBordered = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
const CenteredLabel = styled(Label)`
  margin: 10px;
`;

const SpanWrapper = styled.span`
  color: red;
`;

export default compose(
  graphql(personalAccount, { name: "createPersonalAccount" }),
  graphql(confirmEmail, { name: "confirmEmail" }),
)(SignUpForm);
