import config from "react-global-configuration";
import { uiEnv } from "./uiEnv";
import { versionNumber } from "./version";
import getFeatureFlagsForEnv from "../featureFlags";
import { getKccDomain, isKccUrl } from "../libs/kcc/domainUrls";

const environment = process.env.REACT_APP_ENV || "development";

if (environment in uiEnv) {
  const envConfig = uiEnv[environment];

  // validates tokenExpiration field
  if (!envConfig.tokenExpiration) {
    throw new Error("Token expiration variable is missing.");
  }

  config.set({
    ...envConfig,
    versionNumber,
    featureFlags: { ...getFeatureFlagsForEnv(environment) },
  });
} else {
  throw new Error("Invalid config");
}

/**
 * Get environment name
 * it supports KCC envs
 * @returns
 */
export function getEnvironmentName() {
  const currentUrl = `${window.location.protocol}//${window.location.host}`; // returns the absolute URL of a page

  const isKccDom = isKccUrl(currentUrl);
  let domainName = "";

  if (isKccDom) {
    const configData = getKccDomain(currentUrl);
    domainName = configData;
  } else if (environment === "production") {
    domainName = "kaleidosim";
  } else {
    domainName = "staging";
  }

  return domainName;
}
