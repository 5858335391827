// Action types
export const SET_SELECTED_FILE_EXTENSIONS = "caseFiles/SET_SELECTED_FILE_EXTENSIONS";
export const SELECT_ALL_FILE_EXTENSIONS = "caseFiles/SELECT_ALL_FILE_EXTENSIONS";
export const SET_SELECTED_ALL_CASE_FILES = "caseFiles/SET_SELECTED_ALL_CASE_FILES";
export const SET_SELECTED_COMMON_FILES = "caseFiles/SET_SELECTED_COMMON_FILES";
export const CLEAN_UP_CASEFILES_INFO = "caseFiles/CLEAN_UP_CASEFILES_INFO";
export const FETCH_BDS_URL_SUCCESS = "caseFiles/FETCH_BDS_URL_SUCCESS";
export const FETCH_BDS_URL_START = "caseFiles/FETCH_BDS_URL_START";
export const FETCH_BDS_URL_ERROR = "caseFiles/FETCH_BDS_URL_ERROR";

// Action creators
export const setSelectedFilesExtensions = (data, files) => ({
  type: SET_SELECTED_FILE_EXTENSIONS,
  payload: { data, files },
});

export const setSelectAllFilesExtensions = (data) => ({
  type: SELECT_ALL_FILE_EXTENSIONS,
  payload: { data },
});

export const setSelectedAllCaseFiles = (data, totalSize, checked) => ({
  type: SET_SELECTED_ALL_CASE_FILES,
  payload: { data, totalSize, checked },
});

export const setSelectedCommonFiles = (totalFiles, totalSize, checked, data) => ({
  type: SET_SELECTED_COMMON_FILES,
  payload: { totalFiles, totalSize, checked, data },
});

export const cleanUpCaseFilesInfo = () => ({
  type: CLEAN_UP_CASEFILES_INFO,
  payload: {},
});

export const fetchBDSUrlError = (error) => ({
  type: FETCH_BDS_URL_ERROR,
  payload: { error },
});

export const fetchBDSUrlStart = () => ({
  type: FETCH_BDS_URL_START,
  payload: {},
});

export const fetchBDSUrlSuccess = (data) => ({
  type: FETCH_BDS_URL_SUCCESS,
  payload: { data },
});
