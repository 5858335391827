import React from "react";
import Helmet from "react-helmet";

import FeatureToggle from "./common/FeatureToggle";
import { AIBAT } from "../featureFlags";
import SimvantageIcon from "../assets/img/simvantage/favicon.ico";

/**
 * Component to render Head element based
 */
function DocHead() {
  return (
    <FeatureToggle flag={AIBAT}>
      <Helmet>
        <title>simvantage</title>
        <link rel="shortcut icon" href={SimvantageIcon} />
      </Helmet>
    </FeatureToggle>
  );
}

export default DocHead;
