import { toast } from "react-toastify";
import { isNumber, isBoolean, isObject, isNaN, isString } from "lodash";

import { HIDDEN_PARAMETER_KEY } from "../constants/aibat/aibatJsonFile";
import { TIPS } from "./simvantageTips";

/**
 * Simvantage Json helper function
 */

// Simvantage parameter types
export const PARAMETER_TYPES = {
  unknown: "unknown",
  boolean: "boolean",
  number: "number",
  dictionary: "dictionary",
  hidden: "hidden",
  text: "text",
};

// Exclude symbols from Simvantage parameter key
const excludeSymbols = ["-"];

/**
 * Validates a value from aibatJson file and return a specific type
 * @param value
 * @returns
 */
export const getParameterType = (value: string | number | boolean | any[]) => {
  if ((isString(value) && (value === "true" || value === "false")) || isBoolean(value)) {
    return PARAMETER_TYPES.boolean;
  }

  if (value === "") {
    return PARAMETER_TYPES.text;
  }
  
  if ((isString(value) || isNumber(value)) && !isNaN(+value)) {
    return PARAMETER_TYPES.number;
  }

  // Dictionary === object === group
  if (isObject(value)) {
    return PARAMETER_TYPES.dictionary;
  }

  return PARAMETER_TYPES.unknown;
};
/**
 * Transform json to parameter array of object
 * It assign a type based on the value in every json key:value
 * It assign all the parameters required to be rendered on UI
 * (label,value,events)
 * @returns
 */
export const transformToParamSet = (jsonData: string | number | boolean | object) => {
  try {
    if (typeof jsonData === "object") {
      return Object.keys(jsonData).map((jsonDataKey) => {
        const parameters = jsonData[jsonDataKey];
        return {
          title: jsonDataKey,
          parameters: Object.keys(parameters).map((parameterKey) => {
            const matches = parameterKey.match(/\[(.*?)]/);
            const label = parameterKey.replace(matches?.[0], "").trim();
            const symbol = matches?.[1];
            const value = parameters[parameterKey];

            // check if the parameter in the json file has [hidden] key
            const isHiddenParameter = parameterKey.includes(HIDDEN_PARAMETER_KEY);
            const parameterType = isHiddenParameter ? PARAMETER_TYPES.hidden : getParameterType(value);

            return {
              pathToJSONKey: [jsonDataKey, parameterKey],
              label,
              symbol: excludeSymbols.includes(symbol) ? null : symbol,
              parameterType,
              value:
                parameterType === PARAMETER_TYPES.dictionary
                  ? Object.keys(value).map((key) => {
                      const matches2 = key.match(/\[(.*?)]/);

                      const labelInnerObject = key.replace(matches2?.[0], "").trim();

                      const symbolInnerObject = matches2?.[1];

                      return {
                        pathToJSONKey: [jsonDataKey, parameterKey, key],
                        parameterType: getParameterType(value[key]),
                        value: value[key],
                        label: labelInnerObject,
                        tip: TIPS[labelInnerObject],
                        symbol: excludeSymbols.includes(symbolInnerObject) ? null : symbolInnerObject,
                      };
                    })
                  : value,
              tip: TIPS[label],
            };
          }),
        };
      });
    }

    return [];
  } catch (e) {
    console.error(e); // eslint-disable-line
    toast.error("Something wrong with your JSON file, please try a new one.");
    return [];
  }
};
