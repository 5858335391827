/**
 * KCC urls for all the lite installations
 */
const kccUrls = {
  testEnv: "https://testkcc-dot-app-dot-kaleidosimtestenv.ew.r.appspot.com",
  simvantage_stage: "https://simvantage.app.kaleidosim-test.com",
  simvantage_prod: "https://app.simvantage.com",
};

/**
 * supported kcc domains with its service name
 */
const kccDomains = {
  simvantage: "simvantage",
};

/**
 * Check if an url belongs to KCC domains
 * @param {String} url
 * @returns
 */
function isKccUrl(url) {
  let isKccDomain = false;
  for (const key in kccUrls) {
    if (kccUrls[key] === url) {
      isKccDomain = true;
    }
  }
  return isKccDomain;
}

/**
 * function that return the desired kcc domain
 * this is used to load the theme and logo
 * @param {String} url
 */
function getKccDomain(url) {
  switch (url) {
    case kccUrls.simvantage_stage:
      return kccDomains.simvantage;
    case kccUrls.simvantage_prod:
      return kccDomains.simvantage;
  }
}

module.exports = { isKccUrl, kccUrls, getKccDomain, kccDomains };
