// eslint-disable-next-line no-unused-vars
import React, { lazy } from "react";
import { getKccDomain, isKccUrl, kccDomains } from "../../libs/kcc/domainUrls";

const SimvantageLogo = lazy(() => import("../../components/common/themedLogo/SimvantageLogo"));
const DefaultLogo = lazy(() => import("../../components/common/themedLogo/DefaultLogo"));

/**
 * function to load the Logo given a kcc domain
 * @param {*} host
 * @returns
 */
export function getKccLogo(host) {
  if (host !== null) {
    const isKcc = isKccUrl(host.url);
    if (isKcc) {
      const response = getKccDomain(host.url);
      switch (response) {
        case kccDomains.simvantage:
          return SimvantageLogo;
        default:
          return DefaultLogo;
      }
    }
  }
  return null;
}
