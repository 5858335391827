import React, { Component } from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { Button, Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import compose from "lodash.flowright";
import { connect } from "react-redux";
import ThemedLogo from "../../common/themedLogo";
import LoginForm from "./LoginForm";
import { loginMutation } from "../../../queries/user-queries";
import { saveEmail } from "../../../store/confirmEmail/actions";
import InputCodeModal from "./InputCodeModal";
import domainStore from "../../../helpers/kcc/DomainStore";
import FeatureToggle from "../../common/FeatureToggle";
import { ENABLE_SIGNUP, SIGNUP_DISPLAY_CPU_HOURS_TEXT } from "../../../featureFlags";

class Signin extends Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    this.state = {
      errorSignin: [],
      formArgs: [],
      loading: false, // parameter to indicate loading status
      showAuthModal: false,
      userId: "",
      token: "",
      redirect: false,
    };
    this.handleTwoFactorAuthStatus = this.handleTwoFactorAuthStatus.bind(this);
  }

  componentDidMount() {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("BrowserCompatibilityWarningPageShown");
    } catch (e) {
      console.error(e);
    }
  }

  handleSubmit = async (ev, args) => {
    ev.preventDefault();
    try {
      // set loading to TRUE to indicate the request is in progress
      this.setState({ loading: true });
      const params = args;

      // get the email from the state
      const { email } = this.state.formArgs;
      // store the email in the store
      this.props.onLoginEmailChange(email);
      // execute login mutation
      if (localStorage.getItem("custom-domain") !== null) {
        const domain = domainStore.getDomain();
        params.customUrl = domain.url;
      } else {
        params.customUrl = null;
      }
      const response = await this.props.loginMutation({
        variables: params,
      });
      // get the response from the request
      const { errors, success, accountEnabled, token, refreshToken, twoFactorAuth, userId } = response.data.login;

      if (!success) {
        // Set errors
        this.setState({ errorSignin: errors });
        // if the user did not confirm his account
        if (!accountEnabled) {
          this.setState({ redirect: true });
        } else {
          // set loading state to FALSE to delete spinner icon on the button
          this.setState({ loading: false });
        }
      }
      // check if user has 2FA enabled
      else {
        this.setState({ token, userId });
        if (!twoFactorAuth) {
          // Set the token to the local storage
          try {
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
          } catch (e) {
            console.error(e);
          }
          // set loading state to FALSE to delete spinner icon on the button
          this.setState({ loading: false });
          this.props.history.push("/dashboard");
        } else {
          this.toggleModal();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleTwoFactorAuthStatus(status) {
    this.setState({ loading: false });
    this.toggleModal();
    if (status) {
      try {
        localStorage.setItem("token", this.state.token);
      } catch (e) {
        console.error(e);
      }
      this.props.history.push("/dashboard");
    }
  }

  toggleModal() {
    this.setState((prevState) => ({ showAuthModal: !prevState.showAuthModal }));
  }

  render() {
    return (
      <>
        {this.state.redirect && <Redirect to="new-conf-email" />}
        <div className="app flex-row align-items-center">
          <Container style={{ maxWidth: "117vh" }}>
            <Row className="justify-content-center mb-3">
              <Col md="8">
                <CardGroup>
                  <Card className="p-4">
                    <StyledThemedLogo className="mx-auto d-block" alt="Logo" />
                  </Card>
                </CardGroup>
              </Col>
            </Row>

            {this.state.showAuthModal ? (
              <Row className="justify-content-center">
                <InputCodeModal
                  show={this.state.showAuthModal}
                  shouldDisableMultiFactor={false}
                  handleTwoFactorAuthStatus={this.handleTwoFactorAuthStatus}
                  userId={this.state.userId}
                />
              </Row>
            ) : null}

            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup>
                  <LoginForm
                    loading={this.state.loading}
                    handleSubmit={this.handleSubmit}
                    errors={this.state.errorSignin}
                    formArgs={this.state.formArgs}
                  />
                  <FeatureToggle flag={ENABLE_SIGNUP}>
                    <SignUpCard className="text-white bg-primary py-5 d-md-down-none">
                      <CardBody className="text-center">
                        <div>
                          <h2>Sign up</h2>
                          <FeatureToggle
                            flag={SIGNUP_DISPLAY_CPU_HOURS_TEXT}
                            activeComponent={
                              <>
                                <p>Register now to experience the best in advanced cloud computing.</p>
                                <p>Retrieve 50 free cloud-hours with your first purchase.</p>
                              </>
                            }
                            inactiveComponent={
                              <h5>Register now to experience the best in advanced computational engineering.</h5>
                            }
                          />
                          <Link to="/register">
                            <Button color="primary" className="mt-3" active tabIndex={-1}>
                              Register Now!
                            </Button>
                          </Link>
                        </div>
                      </CardBody>
                    </SignUpCard>
                  </FeatureToggle>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const StyledThemedLogo = styled(ThemedLogo)`
  width: 95%;
`;

const SignUpCard = styled(Card)`
  width: 44%;
`;

const mapDispatchToProps = (dispatch) => ({
  onLoginEmailChange: (email) => {
    dispatch(saveEmail(email));
  },
});

export default compose(connect(null, mapDispatchToProps), graphql(loginMutation, { name: "loginMutation" }))(Signin);
