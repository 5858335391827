// action types
export const UPDATE_CASES = "caseStatus/UPDATE_CASES";
export const SET_CASE_LOADING_STATE = "caseStatus/SET_CASE_LOADING_STATE";
export const SET_CASE_OPEN_STATE = "caseStatus/SET_CASE_OPEN_STATE";
export const SET_CASE_UPLOADING_STATE = "caseStatus/SET_CASE_UPLOADING_STATE";

export const GET_PROJECT_CASES_STATUS_START = "caseStatus/GET_PROJECT_CASES_STATUS_START";
export const GET_PROJECT_CASES_STATUS_SUCCESS = "caseStatus/GET_PROJECT_CASES_STATUS_SUCCESS";
export const GET_PROJECT_CASES_STATUS_FAILURE = "caseStatus/GET_PROJECT_CASES_STATUS_FAILURE";

export const updateCases = (data) => ({
  type: UPDATE_CASES,
  payload: { data },
});

export const setCaseLoading = (caseId) => ({
  type: SET_CASE_LOADING_STATE,
  payload: { caseId },
});

export const setCaseUploading = (caseId) => ({
  type: SET_CASE_UPLOADING_STATE,
  payload: { caseId },
});

export const setCaseOpen = (caseId) => ({
  type: SET_CASE_OPEN_STATE,
  payload: { caseId },
});

export const getProjectCasesStatusStart = () => ({
  type: GET_PROJECT_CASES_STATUS_START,
  payload: {},
});

export const getProjectCasesStatusSuccess = (projectCasesStatus) => ({
  type: GET_PROJECT_CASES_STATUS_SUCCESS,
  payload: { projectCasesStatus },
});

export const getProjectCasesStatusFailure = (error) => ({
  type: GET_PROJECT_CASES_STATUS_FAILURE,
  payload: { error },
});
