import React from "react";

/**
 * Loading parameter indicates that the process to login is being executed
 * once the request is completed loading is set to false
 * @param {boolean} loading
 */
function LoginTextComp() {
  return <p className="text-muted">Sign In to your account</p>;
}

export default LoginTextComp;
