// Action types

export const SLIDER_CHANGE = "shoppingCart/SLIDER_CHANGE";
export const HOURS_INPUT = "shoppingCart/HOURS_INPUT";
export const PRICE_INPUT = "shoppingCart/PRICE_INPUT";
export const EMPTY = "shoppingCart/EMPTY";

// thunk types
export const FETCH_START = "shoppingCart/FETCH_START";
export const FETCH_SUCCESS = "shoppingCart/FETCH_SUCCESS";
export const FETCH_ERROR = "shoppingCart/FETCH_ERROR";

// Action creators
export const slider = (value) => ({
  type: SLIDER_CHANGE,
  payload: { value },
});

export const hoursInput = (value) => ({
  type: HOURS_INPUT,
  payload: { value },
});

export const priceInput = (value) => ({
  type: PRICE_INPUT,
  payload: { value },
});

export const empty = (value) => ({
  type: EMPTY,
  payload: { value },
});

// thunk action creators

export const fetchStart = (displayItem) => ({
  type: FETCH_START,
  payload: { displayItem },
});

export const fetchSuccess = (cartItem) => ({
  type: FETCH_SUCCESS,
  payload: { cartItem },
});

export const fetchError = (error) => ({
  type: FETCH_ERROR,
  payload: { error },
});
