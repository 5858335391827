import React, { Suspense } from "react";

import { SimvantageTheme } from "./themes";

/**
 * Component that load the theme for the app, switches for kcc and given node_envs
 * @param {*} param0
 * @returns
 */
function ThemeProvider({ children }) {
  const theme = <SimvantageTheme />;

  return (
    <Suspense fallback={<div>Loading theme...</div>}>
      {theme}
      {children}
    </Suspense>
  );
}

export default ThemeProvider;
