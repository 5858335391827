/**
 * Constant values for simvantage
 */
export const DEFAULT_AIBAT_JSON_FILE = "reactorparameters";

// key name for CaseName in simvantage json file
export const AIBAT_PARAMETER_CASE_NAME_KEY = "Name";

// simvantage default script
export const AIBAT_RUN_SCRIPT = "start &> $ROOT_DIRECTORY/log.txt";

// default key for simvantage
export const REACTOR_PARAMETERS_KEY = "Reactor parameters";

// Hidden parameter key
export const HIDDEN_PARAMETER_KEY = "[hidden]";
