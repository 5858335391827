import React from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { sendTwoFactorAuthDisabledEmail } from "../../../queries/user-queries";
/**
 * Modal that is displayed after a successfull login with recovery code
 * @param {token, isOpen} props
 * token the JWT string for login
 * isOpen boolean to check if modal should be shown or not
 */
const ReEnableModal = props => {
  const [sendEmail] = useMutation(sendTwoFactorAuthDisabledEmail);

  const onContinueClick = async () => {
    try {
      const { data } = await sendEmail({
        variables: {
          userId: props.userId,
        },
      });
      // check if email was sent
      if (data.sendTwoFactorAuthDisabledEmail) {
        // set the userToken
        try {
          localStorage.setItem("token", props.token);
        } catch (err) {
          console.error(err);
        }
        props.history.push("/dashboard");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal isOpen={props.isOpen} backdrop={false} className="modal-primary modal-dialog-centered">
      <ModalHeader> You have logged in using a recovery code</ModalHeader>
      <ModalBody className="modal-body">
        <h5>
          Your Two Factor Authentication has been disabled, please re-enable it as soon as possible. An email has been
          sent to you.
        </h5>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onContinueClick}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(ReEnableModal);
