import {
  TOGGLE_CONFIRM_RUN_MODAL,
  TOGGLE_TERMINATE_RUN_MODAL,
  TOGGLE_DELETE_CASES_MODAL,
  TOGGLE_ADD_USER_MODAL,
  SHOW_MESSAGE_DIALOG,
  CLOSE_MESSAGE_DIALOG,
} from "./actions";

// reducer
const initialState = {
  showRunModal: false,
  showTerminateModal: false,
  showDeleteModal: false,
  showAddUserModal: false,
  messageDialog: {
    isOpen: false,
    message: "",
    header: "",
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_CONFIRM_RUN_MODAL:
      return { ...state, showRunModal: !state.showRunModal };
    case TOGGLE_TERMINATE_RUN_MODAL:
      return { ...state, showTerminateModal: !state.showTerminateModal };
    case TOGGLE_DELETE_CASES_MODAL:
      return { ...state, showDeleteModal: !state.showDeleteModal };
    case TOGGLE_ADD_USER_MODAL:
      return { ...state, showAddUserModal: !state.showAddUserModal };
    case SHOW_MESSAGE_DIALOG:
      const { message, header } = payload;
      return {
        ...state,
        messageDialog: {
          isOpen: true,
          message,
          header,
        },
      };
    case CLOSE_MESSAGE_DIALOG:
      return { ...state, messageDialog: { ...initialState.messageDialog } };

    default:
      return state;
  }
};

// selectors

export const getShowRunModal = (state) => state?.modals?.showRunModal;
export const getShowTerminateModal = (state) => state?.modals?.showTerminateModal;
export const getShowDeleteModal = (state) => state?.modals?.showDeleteModal;
export const getShowAddUserModal = (state) => state?.modals?.showAddUserModal;
export const getMessageDialog = (state) => state?.modals?.messageDialog;
